<template>
  <ys-detail :icon="detailIcon" :loading="loading" :name="detail.date+'的服务简报'">
    <!-- <Tag size="medium" color="blue" slot="status">{{ detail.status|getBaseDataName('简报状态') }}</Tag> -->
    <template slot="brief">
      <ys-cell title="简报日期" :label="detail.date"/>
      <ys-cell title="发送机构" :label="detail.serOrgName"/>
      <ys-cell title="接收机构" :label="detail.insOrgName"/>
      <ys-cell title="备注" :label="detail.desc"/>
    </template>
    <template slot="main-left">
      <Tabs v-model="tab" name="detail" type="card">
        <Tab-pane label="任务列表" name="1" icon="md-list-box">
          <task :tableHeight="pageListHeight" :mid="detailID" :midData="[detail]" v-if="tab==='1'"></task>
        </Tab-pane>
        <Tab-pane label="相关附件" name="6" icon="md-document">
          <ys-resource :resourceType="2" :tableHeight="pageListHeight" :mid="detailID" v-if="tab==='6'"></ys-resource>
          <ys-empty title="暂未开放，敬请期待！"></ys-empty>
        </Tab-pane>
      </Tabs>
    </template>
    <template slot="main-right">
      <h1 class="h1Title">其他信息</h1>
      <div style="text-align: center">暂未开放！</div>
    </template>
  </ys-detail>
</template>

<script>
import detailMixins from '@/mixins/detail'
import {briefInfo} from "@/api/brief";
import task from '@/views/reportManager/brief/detail/task/index.vue'

export default {
  name: "detail",
  mixins: [detailMixins],
  components: {task},
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.loading = true;
      briefInfo({id: this.detailID}).then(res => {
        this.loading = false;
        if (res.code === 200) {
          this.detail = res.data;
        }
        this.setTagName(this.detail.date+'服务简报')
      })
    }
  }
}
</script>

<style scoped>

</style>