<template>
  <div>
    <ys-page-list
        ref="table"
        action="/brief/check/List"
        :tableHeight="tableHeight"
        :multiBtn="multiBtn"
        :params="params"
        :keyLabel="'briefId'"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="项目名称" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add">新增</Button>
        </ButtonGroup>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="500" title="新增/修改">
      <Edit
          :parentID="mid"
          :midData="midData"
          :modData="tableSelectItem"
          @on-ok="getList"></Edit>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'

export default {
  name: 'projects',
  mixins: [pageList],
  components: {
    Edit: () => import('./edit.vue'),
  },
  data() {
    return {
      params: {
        keyword: '',
        id: '',
      },
      putInFlag: false,
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {width: 250, title: '项目名称', key: 'projectName', align: 'left'},
        {width: 250, title: '保险公司', key: 'insName', align: 'left'},
        {width: 130, title: '保险业务人员', key: 'insUsers', align: 'left'},
        {width: 130, title: '专家组成员', key: 'experts', align: 'left'},
        {minWidth: 130, title: '配合情况', key: 'enterpriseAccept', align: 'left'},
      ]
    }
  },
  computed: {

    multiBtn() {
      return [
        {
          children: [
            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.delItem('/brief/check/Delete', {briefId: this.tableSelectItem[0].briefId, checkIds: this.tableSelectItem.map(item => item.checkId)}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length === 0
            },
          ]
        },
      ]
    },
  },
  mounted() {
    this.params.id = this.mid;
    this.getList();
  },
  methods: {}
}
</script>
