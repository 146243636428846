import {get, post} from 'ys-admin'

/**
 * 获取简报详情
 * @param {Object} params
 * @returns {Object}
 */
export const briefInfo = function (params) {
  return get('/brief/Info', params)
};
/**
 * 获取简报任务列表
 * @param {Object} params
 * @returns {Object}
 */
export const getBriefCheckList = function (params) {
  return get('/brief/check/List', params)
};
/**
 * 获取月报详情
 * @param {Object} params
 * @returns {Object}
 */
export const getMonthInfo = function (params) {
  return get('/orgreport/GetInfo', params)
};
/**
 * 提交报告
 * @param {Object} params
 * @returns {Object}
 */
export const monthCommit = function (params) {
  return post('/orgreport/Commit', params)
};
/**
 * 重新生成报告信息
 * @param {Object} params
 * @returns {Object}
 */
export const reGenReport = function (params) {
  return post('/orgreport/ReGenReport', params)
};

/**
 * 隐患整改情况统计
 * @param {Object} params
 * @returns {Object}
 */
export const StatusByCheck = function (params) {
  return get('/comment/StatusByCheckIds', params)
};
