<template>
  <div class="home">
    <ys-page-list
        ref="table"
        action="/brief/List"
        :params="params"
        keyID="6F21F14050E5BC9AAD260AA61999DAD8"
        :multiBtn="multiBtn"
        :tableHeight="tableHeight"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="发送机构" prop="serOrgId">
          <Input v-model="params.serOrgName" clearable @on-clear="clearOrg('serOrg')" readonly placeholder="发送机构">
            <Button slot="append" icon="ios-search" @click="choiceOrg('serOrg')"></Button>
          </Input>
        </FormItem>
        <FormItem label="接收机构" prop="insOrgId">
          <Input v-model="params.insOrgName" clearable @on-clear="clearOrg('insOrg')" readonly placeholder="接收机构">
            <Button slot="append" icon="ios-search" @click="choiceOrg('insOrg')"></Button>
          </Input>
        </FormItem>
        <FormItem label="开始日期" prop="startAt">
          <ys-date-picker v-model="params.startAt" clearable style="width: 120px" placeholder="开始日期"></ys-date-picker>
        </FormItem>
        <FormItem label="结束日期" prop="endAt">
          <ys-date-picker v-model="params.endAt" clearable style="width: 120px" placeholder="结束日期"></ys-date-picker>
        </FormItem>
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="机构名称" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="detail">
        <Detail :mid="detailID"></Detail>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add">新增简报</Button>
        </ButtonGroup>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="1000" title="新增/修改简报">
      <Edit :mid="detailID" @on-ok="getList"></Edit>
    </ys-modal>
    <ys-modal v-model="downFlag" :width="600" title="导出简报">
      <ys-page-list-export
          exportAction="/excel/MutliExport"
          action="brief/check/List"
          :exportTitle="exportTitle"
          :exportFileName="exportFileName"
          @on-close="downFlag=false"
          keyID="8741EC389FF1F329B46B54FE99790031"
          :params="exportParams"
          :columns="exportColumns"></ys-page-list-export>
    </ys-modal>
    <ys-modal v-model="orgFlag" :width="1200" title="选择机构">
      <org-component :limit="1" @on-ok="getData"></org-component>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import Detail from "@/views/reportManager/brief/detail";
import OrgComponent from "_c/org";

export default {
  name: 'projects',
  mixins: [pageList],
  components: {
    OrgComponent,
    Detail,
    Edit: () => import('./edit.vue'),
  },
  data() {
    return {
      orgFlag: false,
      downFlag: false,
      type: '',
      params: {
        insOrgId: '',
        serOrgId: '',
        startAt: '',
        endAt: '',
        keyword: '',
      },
      exportColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {width: 250, title: '项目名称', key: 'projectName', align: 'left'},
        {width: 250, title: '保险公司', key: 'insName', align: 'left'},
        {width: 130, title: '保险业务人员', key: 'insUsers', align: 'left'},
        {width: 130, title: '专家组成员', key: 'experts', align: 'left'},
        {minWidth: 130, title: '配合情况', key: 'enterpriseAccept', align: 'left'},
      ],
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {title: '简报日期', width: 250, key: 'date', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {title: '发送机构', minWidth: 250, key: 'serOrgName', align: 'left'},
        {minWidth: 200, title: '接收机构', key: 'insOrgName', align: 'left'},
        {minWidth: 250, title: '备注', key: 'desc', align: 'left'},
        {width: 170, title: '创建时间', key: 'createdAt', align: 'center'},
      ]
    }
  },
  computed: {
    exportParams: function () {
      return this.tableSelectItem.map(item => {
        return {id: item.id, limit: -1, page: 1};
      })
    },
    exportTitle: function () {
      return this.tableSelectItem.length >= 1 ? this.tableSelectItem[0].date + '服务简报' : ''
    },
    exportFileName: function () {
      return this.tableSelectItem.length >= 1 ? this.tableSelectItem[0].date + ' ' + this.tableSelectItem[0].insOrgName + ' 的服务简报' : ''
    },
    multiBtn() {
      return [
        {
          children: [
            {
              type: 'detail',
              title: '查看详情',
              targetUrl: '/main/reportManager/brief/detail/' + (this.tableSelectItem.length === 1 ? this.tableSelectItem[0].id : null),
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.delItem('/brief/Delete', {id: this.tableSelectItem[0].id}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1
            },
          ],
        },
        {
          children: [
            {
              click: () => this.downFlag = true,
              title: '导出简报',
              icon: 'md-cloud-download',
              disabled: this.tableSelectItem.length === 0
            },
          ],
        },
      ]
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    clearOrg(type) {
      this.params[type + 'Id'] = '';
      this.params[type + 'Name'] = '';
    },
    choiceOrg(type) {
      this.orgFlag = true;
      this.type = type;
    },
    getData(data) {
      this.params[this.type + 'Id'] = data[0].id;
      this.params[this.type + 'Name'] = data[0].iname;
    },
  }
}
</script>
